<template>
  <div id="hall">
    <header>
      <div class="left">
        <img src="../../img/板块图标 - 副本_slices/logo.png" alt="" />
      </div>
      <ul class="right">
        <li @click="backto()">
          <img src="../../img/上课/返回.png" alt="" />
          <span>返回</span>
        </li>
        <li>
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img src="../../img/上课/帮助 (5).png" alt="" />
              帮助
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                <span @click="tohelp">系统通知</span>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <span @click="tohelp2">系统介绍</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li @click="toset">
          <img src="../../img/上课/设置 (6).png" alt="" />
          <span>设置</span>
        </li>
        <li @click="out">
          <img src="../../img/上课/结束.png" alt="" />
          <span>结束课程</span>
        </li>
      </ul>
    </header>
    <main>
      <div @click="around(btnfull)" class="leftbtn">
        <img v-show="btnfull == 0" src="../../img/上课/未收起.png" alt="" />
        <img v-show="btnfull == 1" src="../../img/上课/收起后.png" alt="" />
      </div>
      <div @click="around(btnfull)" class="rightbtn">
        <img v-show="btnfull == 0" src="../../img/上课/收起后.png" alt="" />
        <img v-show="btnfull == 1" src="../../img/上课/未收起.png" alt="" />
      </div>
      <div class="left" v-show="btnfull == 0">
        <ul class="info">
          <li>
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item
                :name="index"
                v-for="(item, index) in list"
                :key="index"
              >
                <template slot="title">
                  <img
                    v-show="activeName == index"
                    class="img-title"
                    src="../../img/上课/文件夹-开_folder-open (1).png"
                    alt=""
                  />
                  <img
                    v-show="activeName != index"
                    class="img-title"
                    src="../../img/上课/文件夹-关_folder-close (2) 拷贝 3.png"
                    alt=""
                  />
                  <span>{{ item.catalog_name }}</span>
                </template>
                <ul class="list">
                  <li
                    v-for="(item1, index1) in item.catalog"
                    :key="index1"
                    @click="
                      listbg = index1;
                      getcid(item1.id);
                    "
                    :class="{ listbg: activeName == index && listbg == index1 }"
                  >
                    <div class="img">
                      <img
                        v-if="listbg == index1"
                        src="../../img/上课/三角下标（正方形） (1).png"
                        alt=""
                      />
                    </div>

                    <span>{{ item1.catalog_name }}</span>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </div>
      <div class="box">
        <router-view :key="$route.query.dataid"></router-view>
      </div>
      <div class="right" v-show="btnfull == 0">
        <div class="right-info">
          <div class="first">
            <div class="title">
              <img
                src="../../img/上课/矢量智能对象 拷贝 3.png"
                alt=""
                style="width: 0.17rem"
              />
              <b>系统工具</b>
            </div>
            <ul class="btnlist">
              <li
                @click="
                  btnlist = 1;
                  dialog1 = true;
                  getclasses();
                  firstbtn();
                "
              >
                <div class="img" style="background: #e8efff">
                  <img
                    v-show="btnlist != 1"
                    src="../../img/上课/矢量智能对象 拷贝 5.png"
                    alt=""
                  />
                  <img
                    v-show="btnlist == 1"
                    src="../../img/上课/矢量智能对象 拷贝 6.png"
                    alt=""
                  />
                </div>
                <div class="txt">课堂签到</div>
              </li>
              <li
                @click="
                  btnlist = 2;
                  dialog2 = true;
                  kttw();
                "
              >
                <div class="img" style="background: #ddf6f1">
                  <img
                    v-show="btnlist != 2"
                    src="../../img/上课/矢量智能对象 拷贝 5(1).png"
                    alt=""
                  />
                  <img
                    v-show="btnlist == 2"
                    src="../../img/上课/矢量智能对象 拷贝 6(1).png"
                    alt=""
                  />
                </div>
                <div class="txt">课堂提问</div>
              </li>
              <li
                @click="
                  btnlist = 3;
                  dialogTableVisible3 = true;
                  kttj();
                "
              >
                <div class="img" style="background: #e4f8fb">
                  <img
                    v-show="btnlist != 3"
                    src="../../img/上课/矢量智能对象 拷贝 5(3).png"
                    alt=""
                  />
                  <img
                    v-show="btnlist == 3"
                    src="../../img/上课/矢量智能对象 拷贝 6(3).png"
                    alt=""
                  />
                </div>
                <div class="txt">课堂统计</div>
              </li>
              <!-- <li
                @click="
                  btnlist = 4;
                  dia4 = true;
                  smsk();
                "
              >
                <div class="img" style="background: #d9f6d8">
                  <img
                    v-show="btnlist != 4"
                    src="../../img/上课/矢量智能对象 拷贝 5(2).png"
                    alt=""
                  />
                  <img
                    v-show="btnlist == 4"
                    src="../../img/上课/矢量智能对象 拷贝 6(2).png"
                    alt=""
                  />
                </div>
                <div class="txt">扫码授课</div>
              </li> -->
            </ul>
          </div>
          <div class="second">
            <div class="title">
              <img
                src="../../img/上课/矢量智能对象 拷贝 4.png"
                alt=""
                style="width: 0.17rem"
              />
              <b>本授课场景资源</b>
            </div>
            <ul class="secondlist">
              <!-- 1 ppt -->
              <li>
                <ul @click="activenum = 1">
                  <li
                    v-for="(i, v) in rightList"
                    :key="v + '1'"
                    @click="
                      num = v;
                      mine(i.file_type, i.id);
                    "
                  >
                    <div class="sl-left">
                      <p>{{ i.file_name }}</p>
                      <span v-if="i.file_type == '1'">PPT资源</span>
                      <span v-else-if="i.file_type == '2'">文档资源</span>
                      <span v-else-if="i.file_type == '3'">视频资源</span>
                      <span v-else-if="i.file_type == '4'">音频资源</span>
                      <span v-else-if="i.file_type == '5'">图文资源</span>
                      <span v-else-if="i.file_type == '6'">问卷资源</span>
                      <span v-else-if="i.file_type == '7'">模型资源</span>
                      <span v-else-if="i.file_type == '8'">试题资源</span>
                      <span v-else-if="i.file_type == '9'">实景资源</span>
                    </div>
                    <div class="img" v-show="activenum == 1 && num == v">
                      <img src="../../img/上课/观看.png" alt="" />
                    </div>
                    <div class="img" v-show="activenum != 1 || num != v">
                      <img src="../../img/上课/列表 拷贝.png" alt="" />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <el-dialog title="课堂签到" :visible.sync="dialog1" class="dia1">
      <div class="left-dialog">
        <div class="img">
          <img
            v-show="qiandao == 1"
            src="../../img/板块图标 - 副本_slices/暂未签到.png"
            style="width: 310px"
            alt=""
          />
          <span v-if="qiandao == 1" style="font-size: 14px; color: #949292"
            >请点击开始签到按钮生成签到二维码~</span
          >
          <div
            v-show="qiandao == 2"
            class="qrcode qrcode1"
            ref="qrcodeContainer"
          ></div>
        </div>
        <div
          class="btn"
          v-if="qiandao == 1"
          @click="
            qiandao = 2;
            makeQRCode(1);
          "
        >
          开始签到
        </div>
        <div
          class="btn"
          v-else
          @click="
            qiandao = 1;
            stopweb();
          "
        >
          签到截止
        </div>
      </div>
      <div class="right-dialog">
        <div class="title">签到信息</div>
        <p>
          上课班级：
          <span v-for="(i, v) in classes" :key="v">{{ i.class_name }}</span>
        </p>
        <div class="diabox">
          <div class="diatop">
            <el-select v-model="option" placeholder="班级" style="width: 108px">
              <el-option
                :label="i.class_name"
                :value="i.class_name"
                v-for="(i, v) in classes"
                :key="v"
              ></el-option>
              <!-- <el-option label="A1902" value="A1902"></el-option> -->
            </el-select>
            <p>签到人数：{{ stulength }}人</p>
          </div>
          <div class="diabottom">
            <ul>
              <!-- <li>
                <div class="img">
                  <img src="../../img/登录_slices/微信@2x.png" alt="" />
                </div>
                <span>张三</span>
              </li> -->
              <li v-for="(i, v) in tuisong" :key="v">
                <div class="img">
                  <img src="../../img/登录_slices/微信@2x.png" alt="" />
                </div>
                <span>{{ i.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="课堂提问" :visible.sync="dialog2" class="dia2">
      <div class="left" v-if="kttwcode == 1">
        <div class="top">课堂人数：{{ kttwList.length }}人</div>
        <ul class="stunum">
          <li v-for="(item, index) in kttwList" :key="index">
            <img
              src="../../img/登录_slices/组 1 拷贝 3@2x.png"
              alt=""
              :class="{ dia2active: namesactive == index }"
            />
            <p>{{ item.user.name }}</p>
          </li>
        </ul>
      </div>
      <div
        class="left"
        style="display: flex; align-items: center; justify-content: center"
        v-else-if="kttwcode == 2"
      >
        <div class="qrcode qrcode2" ref="qrcodeContainer1"></div>
      </div>
      <div class="right">
        <div class="name">
          <div class="img">
            <img :src="nameUrl" alt="" />
          </div>

          <span id="ko"></span>
        </div>
        <div class="btnbox">
          <el-button
            class="button btn1"
            :class="{ active: active == 1 }"
            @click="
              chosename();
              active = 1;

              kttw();
            "
            :disabled="flag"
          >
            <!-- <span>开始点名</span> -->
            开始点名
          </el-button>
          <el-button
            class="button btn2"
            :class="{ active: active == 2 }"
            @click="
              chosename1();
              active = 2;
            "
          >
            <span>停止点名</span>
          </el-button>
        </div>
        <div class="goinclass">
          <el-button class="button" @click="makeQRCode(2)">
            <span @click="kttwcode = 2">加入班级</span>
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="课堂统计" :visible.sync="dialogTableVisible3">
      <div class="dia-box">
        <ul>
          <li
            @click="
              dianum = 1;
              firstbtn();
            "
          >
            <b :class="{ li_b: dianum == 1 }">签到统计</b>
          </li>
          <!-- <span></span> -->
          <li
            @click="
              dianum = 2;
              secondbtn();
            "
          >
            <b :class="{ li_b: dianum == 2 }">答题统计</b>
          </li>
          <!-- <span></span> -->
          <li
            @click="
              dianum = 3;
              thirdbtn();
            "
          >
            <b :class="{ li_b: dianum == 3 }">提问统计</b>
          </li>
        </ul>
        <div class="dia-info">
          <div v-show="dianum == 1" class="firstech">
            <table>
              <tr>
                <th>序号</th>
                <th>姓名</th>
                <th>班级</th>
                <th>学号</th>
                <th>是否签到</th>
              </tr>
              <tr v-for="(item, index) in qiandaoInfo" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.user.name }}</td>
                <td>{{ item.class_name.class_name }}</td>
                <td>{{ item.user.stu_number }}</td>
                <td>{{ item.status == 0 ? "否" : "是" }}</td>
              </tr>
            </table>
          </div>
          <div v-show="dianum == 2" class="secondech">
            <table>
              <tr>
                <th>序号</th>
                <th>姓名</th>
                <th>班级</th>
                <th>学号</th>
                <th>是否答题</th>
                <th>正确率</th>
              </tr>
              <tr v-for="(item, index) in datiInfo" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.user.name }}</td>
                <td>{{ item.class_name.class_name }}</td>
                <td>{{ item.user.stu_number }}</td>
                <td>是</td>
                <td>{{ item.precision }}%</td>
              </tr>
            </table>
          </div>
          <div v-show="dianum == 3" class="third">
            <table>
              <tr v-for="(item, index) in tiwenList" :key="index">
                <th>序号</th>
                <th>姓名</th>
                <th>班级</th>
                <th>学号</th>
                <th>提问次数</th>
              </tr>
              <tr v-for="(item, index) in tiwenList" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.user.name }}</td>
                <td>{{ item.class_name.class_name }}</td>
                <td>{{ item.user.stu_number }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="扫码授课" :visible.sync="dia4" class="dialog4">
      <div class="box">功能建设中，敬请期待！</div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import $ from "jquery";
import QRCode from "qrcodejs2";
var arr = []; // 签到arr

export default {
  data() {
    return {
      btnlist: "",
      num: 0,
      nums: 0,
      activenum: 1,
      activeName: "0",
      btnfull: 0,

      listbg: "",
      list: "",
      dialog1: false,
      option: "",
      qiandao: 1,
      dialog2: false,
      ydm: "",
      active: 2,
      nameUrl: require("../../assets/logo.png"),
      namesactive: 0,
      flag: false, //连续点名解决
      // names: [],
      dialogTableVisible3: false,
      dianum: 1,
      bdnum: 1,
      rightList: "",
      // pptlist: "", // ppt
      // wenlist: "", // 文档
      // videolist: "", // 视频
      // mp3list: "", // 音频
      // picturelist: "", // 图文
      // questionlist: "", // 问卷
      // modellist: "", // 模型
      // topiclist: "", // 试题
      // reallist: "", // 实景

      qiandaoInfo: "",
      datiInfo: "",
      tiwenList: "",
      classes: "",
      dia4: false,
      tuisong: "",
      stulength: "",
      kttwList: "",
      kttwcode: 1,
      val: "",
      websock: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 10 * 1000, //10秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
    };
  },
  mounted() {
    this.getinfo();
    console.log(this.$route.path);
    if (this.$route.path == "/hall/three") {
      $("main .left").css({
        display: "none",
      });
      $("main .right").css({
        display: "none",
      });
      $("main .leftbtn").css({
        display: "none",
      });
      $("main .rightbtn").css({
        display: "none",
      });
    }
  },
  methods: {
    getinfo() {
      util.post("/catalog", { csid: this.$route.query.csid }, (res) => {
        console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.getcid(res.data[0].catalog[0].id);
      });
    },
    // 课堂提问
    kttw() {
      util.post(
        "/questionAskList",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          console.log(res);
          this.kttwList = res.data;
          if (this.kttwList == "") {
            this.kttwcode = 2;
            // this.makeQRCode();
          } else {
            this.kttwcode = 1;
          }
        }
      );
    },
    // 课堂统计
    kttj() {
      this.firstbtn();
    },
    // 课堂统计-签到统计
    firstbtn() {
      util.post(
        "/data_qiandao",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          console.log(res);
          if (res.code == 1) {
            this.qiandaoInfo = res.data;
          }
        }
      );
    },
    // 课堂统计-答题统计
    secondbtn() {
      util.post(
        "/data_dati",
        {
          csid: this.$route.query.csid,
          class_id: localStorage.getItem("class_id"),
        },
        (res) => {
          console.log(res);
          if (res.code == 1) {
            this.datiInfo = res.data;
          }
        }
      );
    },
    // 课堂统计-提问统计
    thirdbtn() {
      util.post(
        "/data_tiwen",
        {
          csid: this.$route.query.csid,
          class_id: localStorage.getItem("class_id"),
        },
        (res) => {
          console.log(res);
          this.tiwenList = res.data;
        }
      );
    },
    toset() {
      this.$router.push("/set");
    },
    // 结束课程
    out() {
      util.post(
        "/courseEnd",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push("/main/starts");
          }
        }
      );
    },
    // 左侧点击事件   点击切换右侧盒子内容
    getcid(id) {
      util.post("/catalogFile", { catalog_id: id }, (res) => {
        console.log(res.data);
        this.rightList = res.data;
        if (this.$route.query.file_type) {
          this.mine(this.$route.query.file_type, this.$route.query.dataid);
        } else {
          this.mine(res.data[0].file_type, res.data[0].id);
        }

        // this.myThings("ppt", res.data[0].id);
        // this.pptlist = res.data.filter((item) => item.file_type == "1");
        // this.wenlist = res.data.filter((item) => item.file_type == "2");
        // this.videolist = res.data.filter((item) => item.file_type == "3");
        // this.mp3list = res.data.filter((item) => item.file_type == "4");
        // this.picturelist = res.data.filter((item) => item.file_type == "5");
        // this.questionlist = res.data.filter((item) => item.file_type == "6");
        // this.modellist = res.data.filter((item) => item.file_type == "7");
        // this.topiclist = res.data.filter((item) => item.file_type == "8");
        // this.reallist = res.data.filter((item) => item.file_type == "9");
      });
    },
    mine(type, id) {
      switch (type) {
        case "1":
          this.myThings("ppt", id);
          break;
        case "2":
          this.myThings("artical", id);
          break;
        case "3":
          this.myThings("video", id);
          break;
        case "4":
          this.myThings("music", id);
          break;
        case "5":
          this.myThings("picture", id);
          break;
        case "6":
          this.myThings("question", id);
          break;
        case "7":
          this.myThings("three", id);
          break;
        case "8":
          this.myThings("topic", id);
          break;
        default:
          this.myThings("real", id);
          break;
      }
    },
    myThings(thing, id) {
      this.$router.push({
        path: "/hall/" + thing,
        query: { csid: this.$route.query.csid, dataid: id },
      });
    },
    around(i) {
      if (i == 0) {
        this.btnfull = 1;
      } else {
        this.btnfull = 0;
      }
      if (this.btnfull == 1) {
        $(".leftbtn").css({
          left: "0",
        });
        $(".rightbtn").css({
          right: "0",
        });
        $(".btnList").css({
          right: "254px",
        });
      } else {
        $(".leftbtn").css({
          left: "160px",
        });
        $(".rightbtn").css({
          right: "250px",
        });
        $(".btnList").css({
          right: "300px",
        });
      }
    },
    getclasses() {
      util.post("/classList", {}, (res) => {
        console.log(res);
        this.classes = res.data;
      });
    },
    chosename() {
      let that = this;
      this.flag = true;
      this.ydm = setInterval(function () {
        let index = Math.floor(Math.random() * that.kttwList.length);
        let val = that.kttwList[index];
        console.log(index);
        that.val = val;
        that.namesactive = index;
        $("#ko").html(val.user.name);
      }, 50);
    },
    chosename1() {
      // $("#ko").css({ color: "#f00" });
      clearInterval(this.ydm);
      this.flag = false;
      console.log(this.val);
      util.post("/questionAsk", {
        md_str: localStorage.getItem("md"),
        uid: this.val.uid,
        class_id: this.val.class_id,
      });
    },
    makeQRCode(i) {
      if (i == 1) {
        //签到
        $(".qrcode1").html("");

        util.post(
          "/qd_code",
          {
            md_str: localStorage.getItem("md"),
          },
          (res) => {
            if (res.code == 1) {
              this.$nextTick(() => {
                new QRCode(this.$refs.qrcodeContainer, {
                  text:
                    // 192.168.1.162:8092
                    "http://zy.yunlearning.com:8084/#/pages/index/signs/?cid=" +
                    localStorage.getItem("cid") +
                    "&md_str=" +
                    localStorage.getItem("md") +
                    "&class_id=" +
                    localStorage.getItem("class_id") +
                    "&ss=1" +
                    "&csid=" +
                    this.$route.query.csid,
                  width: 567,
                  height: 567,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
              });
              this.initWebSocket();
            }
          }
        );
      } else {
        //加入班级
        $(".qrcode2").html("");
        util.post(
          "/qd_code",
          {
            md_str: localStorage.getItem("md"),
          },
          (res) => {
            if (res.code == 1) {
              this.$nextTick(() => {
                new QRCode(this.$refs.qrcodeContainer1, {
                  text:
                    // 192.168.1.162:8092
                    "http://zy.yunlearning.com:8084/#/pages/index/signs/?cid=" +
                    localStorage.getItem("cid") +
                    "&md_str=" +
                    localStorage.getItem("md") +
                    "&class_id=" +
                    localStorage.getItem("class_id") +
                    "&ss=1" +
                    "&join=1" +
                    "&csid=" +
                    this.$route.query.csid,
                  width: 567,
                  height: 567,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
              });
              this.initWebSocket();
            }
          }
        );
      }
    },
    stopweb() {
      $(".qrcode").html("");
      this.websock.close();
    },
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
    initWebSocket() {
      //初始化weosocket
      const wsuri = "ws://zy.yunlearning.com:2345";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.websock.readyState == 1) {
          //如果连接正常
          self.websock.send("heartCheck");
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      let actions = {
        t_token: this.getCookie("token"),
        first: 1,
        md_str: localStorage.getItem("md"),
      };
      this.websocketsend(JSON.stringify(actions));
      //开启心跳
      this.start();
    },
    websocketonerror() {
      //连接建立失败重连
      // this.initWebSocket();
      this.reconnect();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      console.log(redata, 111);

      let name = redata.data;
      if (redata.status == 10001) {
        arr.push(name);
        console.log(arr, name);
        this.tuisong = arr;
        this.stulength = arr.length;
      }

      // if (redata.status == 10000) {
      //   let arr = redata.data;
      //   this.tuisong = arr;
      //   this.stulength = arr.length;
      // }

      this.reset();
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      this.reconnect();
    },

    tohelp() {
      this.$router.push({
        path: "/help",
        query: {
          id: 1,
        },
      });
    },
    tohelp2() {
      this.$router.push({
        path: "/help",
        query: {
          id: 2,
        },
      });
    },
    backto() {
      if (this.$route.path == "/hall/real") {
        this.$router.go(0);
      }
      if (this.$route.path != "/hall") {
        this.$router.push({
          path: "/hallindex",
          query: { csid: this.$route.query.csid },
        });
      }
    },
  },
  watch: {
    $route(now, old) {
      console.log(now.path, old);
      if (now.path == "/hall/real") {
        $("main .left").css({
          display: "none",
        });
        $("main .right").css({
          display: "none",
        });
        $("main .leftbtn").css({
          display: "none",
        });
        $("main .rightbtn").css({
          display: "none",
        });
      } else {
        $("main .left").css({
          display: "block",
        });
        $("main .right").css({
          display: "block",
        });
        $("main .leftbtn").css({
          display: "block",
        });
        $("main .rightbtn").css({
          display: "block",
        });
      }
      // immediate: true;
    },
  },
};
</script>
<style lang="less" scoped>
#hall {
  // min-height: 937/96in;
  height: 100vh;
  header {
    height: 60/96in;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.35rem /* 35/100 */ 0 0.2rem /* 56/100 */;
    border-bottom: 1px solid #cdcdcd;
    .left {
      display: flex;
      align-items: center;
      img {
        height: 36/96in;
        margin-right: 8px;
      }
    }
    .right {
      display: flex;
      li {
        margin-left: 0.33rem /* 33/100 */;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 22/96in;
          margin-right: 7px;
        }
      }
    }
  }
  main {
    // position: relative;
    height: calc(100vh - 0.625in);
    // min-height: 9.14 * 96px;
    // min-height: 100px;
    display: flex;
    .leftbtn,
    .rightbtn {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }
    .leftbtn {
      left: 160px;
    }
    .rightbtn {
      right: 250px;
    }

    .left {
      z-index: 1;
      width: 160px;
      background-color: #fff;
      // transform: translateX(-100%);
      // border-right: 1px solid #cdcdcd;
      .info {
        width: 160px;
        > li {
          padding-top: 47px;
          .img-title {
            margin-left: 22px;
            margin-right: 14px;
          }
          span {
            font-size: 16px;
          }
        }
        /deep/.el-collapse {
          border-bottom: none;
          border-top: none;
        }
        /deep/.el-collapse-item__header,
        /deep/.el-collapse-item__wrap {
          border-bottom: none;
        }
        /deep/.el-collapse-item__content {
          padding-bottom: 0;
        }
        .list {
          background-color: rgba(204, 230, 255, 0.18);
          li {
            display: flex;
            height: 32px;
            line-height: 32px;
            color: #000;
            font-size: 500;
            cursor: pointer;
            span {
              font-size: 14px;
            }
            .img {
              width: 8px;
              margin-left: 33px;
              margin-right: 11px;
              img {
                width: 100%;
              }
            }
          }
          .listbg {
            // background: url("../../img/上课/矩形 3 拷贝 2.png");
            background-image: linear-gradient(
              to right,
              #1c5eff,
              rgb(62, 173, 247)
            );
            color: #fff;
          }
        }
      }
    }
    .box {
      width: 100%;
      min-height: 9.131in;
      background-color: #eff0f2;
    }
    .right {
      z-index: 1;
      width: 250px;
      background-color: #fff;
      .right-info {
        width: 250px;
        .title {
          display: flex;
          align-items: center;
          height: 33px;
          background-color: #dddfe2;
          img {
            margin: 0 13px;
          }
        }
        .first {
          .btnlist {
            padding: 27px 34px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              text-align: center;
              flex-wrap: wrap;

              color: #4e4e4e;
              font-size: 14px;
              margin: 2px;
              margin-bottom: 40px;
              .img {
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .second {
          height: 500px;
          .secondlist::-webkit-scrollbar {
            width: 0;
          }
          .secondlist {
            padding: 26px 37px 0 23px;
            height: 467px;
            overflow: auto;
            > li {
              ul {
                width: 100%;
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 46px;
                  cursor: pointer;
                  .sl-left {
                    font-size: 16px;
                    span {
                      font-size: 12/96in;
                      color: #4e4e4e;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dia1 {
  /deep/.el-dialog__body {
    display: flex;
    justify-content: space-between;
    padding: 30px 81px 30px 51px !important;
    .left-dialog {
      width: 567px;
      height: 627px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      .img {
        width: 567px;
        height: 567px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        img {
          width: 310px;
        }
      }
      .btn {
        width: 120px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #3164df;
        color: #fff;
        margin: 0 auto;
        margin-top: 22px;
      }
    }
    .right-dialog {
      width: 647px;
      height: 627px;
      .title {
        font-size: 16px;
        margin: 4px 0;
        font-weight: bold;
        color: #2c2c2c;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #2c2c2c;
        margin: 10px 0 20px;
      }
      .diabox {
        width: 647px;
        height: 558px;
        border: 1px solid #a8a8a8;
        .diatop {
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid #a8a8a8;
          padding: 0 26px;
          p {
            margin: 0;
            margin-left: 128px;
          }
        }
        .diabottom {
          height: 502px;
          overflow: auto;
          ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0 14px 0 0;
            li {
              height: 90px;
              text-align: center;
              margin-left: 16px;
              margin-top: 22px;
              .img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-bottom: 16px;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.dia2 {
  /deep/.el-dialog {
    width: 1400px !important;
    height: 780px;
    // margin: 0 auto !important;
  }
  /deep/.el-dialog__body {
    padding: 30px 70px !important;
    display: flex;
    justify-content: center;
    .left {
      width: 836px;
      height: 600px;
      background: #f9fafe;
      border: 1px solid #a8a8a8;
      margin-right: 23px;
      font-size: 14px;
      .top {
        height: 37px;
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .stunum::-webkit-scrollbar {
        width: 0;
      }
      .stunum {
        height: 563px;
        padding: 8px 0;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        li {
          width: 60px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0 22px 63px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .dia2active {
        border: 1px solid rgb(255, 24, 24);
      }
    }
    .right {
      width: 234px;
      height: 600px;
      background: #f9fafe;
      border: 1px solid #a8a8a8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .name {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          width: 100px;
          height: 100px;
          overflow: hidden;
          margin-bottom: 28px;
          border-radius: 50%;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .btnbox {
        display: flex;
        justify-content: space-between;
      }
      .button {
        width: 100px;
        height: 36px;
        border-radius: 3px;
        // line-height: 36px;
        text-align: center;
        margin-top: 76px;
        display: inline-block;
        border: 1px solid #cdcdcd;
        font-size: 16px;
        cursor: pointer;
      }
      .btn1 {
        background: #f3f3f3;
        color: #1c5eff;
      }
      .active {
        background-color: #1c5eff;
        color: #fff;
      }
      #ko {
        font-size: 18px;
      }
    }
  }
}
dialog4 {
  /deep/.el-dialog__body {
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: bold;
    }
  }
}
/deep/.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog__header {
  text-align: center;
  color: #2c2c2c;
  font-weight: bold;
}
/deep/.el-dialog {
  width: 1400px !important;
  height: 780px;
  margin: 0 auto !important;
  .dia-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 68px;
      width: 100%;
      height: 52px;
      li {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: 16px;
        color: #2c2c2c;
        line-height: 52px;
        cursor: pointer;
        b {
          font-weight: 400;
          height: 51px;
        }
        .li_b {
          color: #1c5eff;
          font-weight: bold;
          border-bottom: 3px solid #1c5eff;
        }
      }
      span {
        display: inline-block;
        width: 1px;
        height: 40px;
        background: #c5c5c5;
      }
    }
    .dia-info {
      width: 100%;
      margin: 20px 0 0 0;
      ul {
        width: 100%;
        margin: 0;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dddfe2;
      }
      th {
        background-color: #e9ebee;
      }
      td,
      th {
        height: 52px;
        text-align: center;
        border-collapse: collapse;
        border: 1px solid #dddfe2;
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 !important;
}

// 加入班级
.qrcode2 {
  /deep/img {
    width: 310px;
  }
}
</style>